import {Card, Col, Divider, Row} from "antd";
import Title from "./Title";
import "./ConvertFile.css";

function ConvertGuideConvert(props){
	const {from, to} = props;

	return <Card style={{marginTop: 20}} className="guide">
		<h2>Công cụ chỉnh sửa, chuyển đổi hình ảnh miễn phí online tại conver68.com</h2>

	</Card>
}
export default ConvertGuideConvert;