// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    height: 55px;
    text-align: center;
    border: none;

    border-radius: 50px;
    transition: all .4s ease-in-out;
    background: linear-gradient(to left, #FFF, #FFF);
    box-shadow: 0 0 13px 0 #636363;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
}

.btn:hover{
    background: #0e1623;
    color: #fff;
    transition: all .4s ease-in-out;
}

.title h1{
    background: -webkit-linear-gradient(#243B55, #020915);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/styles/common.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,YAAY;;IAEZ,mBAAmB;IACnB,+BAA+B;IAC/B,gDAAgD;IAChD,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,+BAA+B;AACnC;;AAEA;IACI,qDAAqD;IACrD,6BAA6B;IAC7B,oCAAoC;AACxC","sourcesContent":[".btn {\n    width: 200px;\n    font-size: 16px;\n    font-weight: 600;\n    cursor: pointer;\n    height: 55px;\n    text-align: center;\n    border: none;\n\n    border-radius: 50px;\n    transition: all .4s ease-in-out;\n    background: linear-gradient(to left, #FFF, #FFF);\n    box-shadow: 0 0 13px 0 #636363;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #333;\n}\n\n.btn:hover{\n    background: #0e1623;\n    color: #fff;\n    transition: all .4s ease-in-out;\n}\n\n.title h1{\n    background: -webkit-linear-gradient(#243B55, #020915);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
