import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { Col, Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import { API_BACKEND, IMAGE_EMPTY } from "../helper/config";
import axios from "axios";
import BlogsItem from "./BlogsItem";
import { Helmet } from "react-helmet-async";
import ChuyenMucItem from "./ChuyenMucItem";
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from "react-js-pagination";
import { useNavigate, useSearchParams } from "react-router-dom";

function ChuyenMuc(states) {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal] = useState(1);
  const navigate = useNavigate();
  const page = parseInt(searchParams.get("page") || "1", 10);

  const handlePagination = (pageNumber) => {

    // window.location.href will reload entire page
    navigate('/chuyenmuc?page='+pageNumber); // Replace '/desired-url' with your target URL
  };
  useEffect(() => {


    const url = API_BACKEND + `postscategory/chuyen-muc?page=`+page;
    console.log(url);
    setState(states);
    setLoading(true)
    axios(url, {
      mode: "no-cors",
      withCredentials: true,
      credentials: "same-origin",
    })
      .then((res) => {
        const { data, message, error } = res.data;
        if (!error) {
          setListData(data);
          setTotal(res.data.pagination.total)
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchParams]);
  // function to handle next and previous.
  const meta = "Chuyên mục tin tức và hướng dẫn cách sử dụng covnert"
  const des = "chuyên mục của convert 68 cập nhật thông báo các tin tức trang chuyển đổi hình ảnh và hướng dẫn cách sử dụng chuyển đổi tại đây."
  //render
  return (
    <Row>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta}</title>
        <meta name="keywords" content={meta} />
        <meta name="description" content={des} />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={meta} />
        <meta property="og:description" content={meta} />
      </Helmet>
      <Col className="gutter-row" span={3}></Col>
      <Col className=" gutter-row" span={18}>
        <div className="headline" style={{ textAlign:"center" }}>
          <h2 style={{ color: "red", fontSize: 35 }}>{state.category}</h2>
        </div>
        {/* spinner */}
        {loading && <Loading />}
        <Row gutter={24}>
          {/* news items mapping */}
          {!loading &&
            listData.map((items, id) => {
              return (
                <Col xs={24} sm={12} md={6} className="row"    style={{ margin: "10px 0px" }}
                key={id}>
               
                  <Layout>
                    <Content>
                      <ChuyenMucItem
                        title={
                          (items.title ? items.title : "") + ".."
                        }
                        slug={items.slug}
                        description={
                          (items.short_description
                            ? items.short_description.slice(0, 85)
                            : "") + "..."
                        }
                        urlToImage={
                          items.urlToImage
                            ? items.urlToImage
                            : IMAGE_EMPTY
                        }
                        newsURL={items.urlToImage}
                      />
                    </Content>
                  </Layout>
                </Col>
              );
            })}
        </Row>
        {!loading && 
          <div className="d-flex justify-content-center mt-5">
            <Pagination
              activePage={page}
              itemsCountPerPage={12}
              totalItemsCount={total}
              onChange={handlePagination}
              nextPageText={"Next"}
              prevPageText={"Prev"}
              firstPageText={"First"}
              lastPageText={"Last"}
              // overwriting the style
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
          }
      </Col>
      <Col className="gutter-row" span={3}></Col>
    </Row>
  );
  
}

export default ChuyenMuc;
