import {Card, Col, Divider, Row} from "antd";
import Title from "./Title";
import "./ConvertFile.css";

function ConvertGuideTrangChu(props){
	const {from, to} = props;

	return <Card style={{marginTop: 20}} className="guide">
		<h1>convert 68 - Trình Chỉnh Sửa Hình Ảnh Miễn Phí Trực Tuyến</h1>
		<div>Convert 68 cung cấp mọi công cụ <strong><a href="https://convert68.com" title="chỉnh sửa hình ảnh">chỉnh sửa hình ảnh</a></strong> trực tuyến, chỉnh sửa nhiều hình ảnh cùng lúc và luôn luôn miễn phí để phục vụ cho mọi người.</div>
        <h2>Convert 68  - Trình chỉnh sửa hình ảnh trực tuyến Miễn Phí đáng tin cậy dành cho bạn, Công Cụ tiên tiến nhất được cả thế giới sửa dụng</h2>
        <p>convert68.com là trang web chỉnh sửa hình ảnh trực tuyến một cách đơn giản, nhanh chóng và hoàn toàn miễn phí. không ngừng phát triển công cụ để chỉnh sửa và <strong>chuyển đổi hình ảnh</strong> một cách toàn vẹn và dễ dàng. Chúng tôi sẽ không ngừng phát triển những công cụ chuyển đổi hình ảnh và video toàn vẹn nhất.</p>
        <h2>Công cụ chuyển đổi hình ảnh đa dạng tại convert68</h2>
        <ul><li>Chuyển đổi png sang dạng khác:</li>
            <ol>
            <li>chuyển file png sang jpg</li>
            <li>chuyển file png sang jpeg</li>
            <li>chuyển file png sang pdf</li>
            <li>chuyển png sang ico</li>
            </ol>
            <li>Chuyển đổi jpg sang dạng khác:</li>
            <ol>
            <li>chuyển file jpg sang png</li>
            <li>chuyển file jpg sang jpeg</li>
            <li>chuyển file jpg sang pdf</li>
            <li>chuyển jpg sang ico</li>
            </ol>
            <li>Chuyển đổi jpeg sang dạng khác:</li>
            <ol>
            <li>chuyển file jpeg sang jpg</li>
            <li>chuyển file jpeg sang png</li>
            <li>chuyển file png sang pdf</li>
            <li>chuyển jpeg sang ico</li>
            </ol>
            <li>Chuyển đổi webp sang dạng khác:</li>
            <ol>
            <li>chuyển file webp sang jpg</li>
            <li>chuyển file webp sang png</li>
            <li>chuyển file webp sang jpeg</li>
            </ol>
            <li>Chuyển file mp4 sang m3u8</li>
            </ul>
            <p>... Và sẽ còn nhiều chức năng sẽ được tiếp tục cập nhật trong tương lai</p>
        <h2>Ưu Điểm Khi Sử Dụng Chuyển Đổi Hình Ảnh Tại convert68</h2>
        <p>convert68 là trang web chuyển đổi hình ảnh đa dạng, không ngừng cải thiện chức năng, có nhiều ưu điểm khi chọn nơi đây để chuyển đổi:</p>
        <ul>
        <li>Giao diện gọn gàng, dễ dùng</li>
        <li>Dung lượng giảm tối thiểu, nhưng vẫn đảm bảo chất lượng hình ảnh rõ nét</li>
        <li>Cho phép người dùng chuyển đổi nhiều hình ảnh cùng một lúc.</li>
        </ul>
	</Card>
}
export default ConvertGuideTrangChu;