// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home__convert{
    padding: 130px 50px 150px;
    width: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    margin-top: -96px;

}

@media screen and (max-width: 991px) {
    .home__convert{
        padding: 120px 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,yDAA+C;IAC/C,sBAAsB;IACtB,iBAAiB;;AAErB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".home__convert{\n    padding: 130px 50px 150px;\n    width: 100%;\n    background-image: url(\"../../../public/bg.svg\");\n    background-size: cover;\n    margin-top: -96px;\n\n}\n\n@media screen and (max-width: 991px) {\n    .home__convert{\n        padding: 120px 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
